/*index.css*/
/*Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/index.css*/

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Domine:wght@400..700&family=League+Spartan:wght@100..900&display=swap');

@tailwind base;
@tailwind utilities;
@tailwind components;

@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}
html {
  font-size: 16px; /* Base size */
}
p {
  font-size: 1rem; /* 16px */
}

/* Example of a simple reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  overflow-x: hidden;
}


html {
  font-size: 100%; /* This makes 1rem = 16px */
  scroll-behavior: smooth; /* Smooth scrolling for anchor links */
}
a {
  color: inherit; /* Inherit color from parent */
  text-decoration: none; /* Remove underline */
}

ul, ol {
  list-style: none; /* Remove default list styles */
  padding-left: 0; /* Remove default padding */
}

button {
  font-family: inherit;
  cursor: pointer;
}
