/* LoadingSpinner.css */
.loading-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top left, 
    rgba(248, 244, 255, 0.5), 
    rgba(48, 47, 80, 0.5)
  ); /* Opaque gradient background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure this is higher than any other z-index on the page */
}




.bowl {
  position: relative;
  border-radius: 50%;
  width: 125px; /* Reduce the size */
  height: 125px; /* Reduce the size */
  background: radial-gradient(circle, white 0%, white 76%, rgba(0, 0, 0, 0.1) 100%);
  overflow: hidden;
}

.bowl:before {
  content: "";
  width: 125px; /* Match the bowl size */
  height: 125px; /* Match the bowl size */
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: radial-gradient(circle, white 0%, white 60%, rgba(0, 0, 0, 0.1) 81%, black 100%);
}

.bowl .inner {
  position: relative;
  width: 125px; /* Match the bowl size */
  height: 125px; /* Match the bowl size */
  border-radius: 50%;
  overflow: hidden;
}

.bowl .fill {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(to top, #8e44ad, #9b59b6);
  transition: height 0.3s ease-out;
}

.bowl .waveShape {
  width: 125px; /* Match the bowl size */
  height: 50px; /* Adjust proportionally */
  fill: #8e44ad;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1em; /* Adjust font size */
  color: #9b59b6;
  z-index: 3;
  pointer-events: none; /* Prevent text from interfering with bowl interactions */
}
